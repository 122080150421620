// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

@import 'sections/section-gallerie';
/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-header';
@import 'modules/module-soumission';


p {
    font-family: $font-family-2;
}

/*******************************************************************************
* BURST
*******************************************************************************/

.carousel {
  &.carousel-slider {
    top: 0;
    left: 0;

    .carousel-fixed-item {
      &.with-indicators {
        bottom: 68px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      z-index: 1;
    }

    .carousel-item {
      width: 100%;
      height: 100%;
      min-height: 400px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  overflow: hidden;
  position: relative;
  width: 100%;
  perspective: 800px;
  height: 50vw;
  max-height: 900px;
  transform-style: preserve-3d;
  //transform-origin: 0% 50%;

  .carousel-item {
    visibility: hidden;
    width: 30%;
    max-width: 350px;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
    }
  }

  .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;

    .indicator-item {
      &.active {
        background-color: #fff;
      }

      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 8px;
      width: 8px;
      margin: 24px 4px;
      background-color: rgba(255,255,255,.5);

      transition: background-color .3s;
      border-radius: 50%;
    }
  }

  // Materialbox compatibility
  &.scrolling .carousel-item .materialboxed,
  .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro {
    background: #0889b0;
    padding: 100px 5% 100px 10%;
    display: flex;
    .left-side {
        width: 50%;

        h3 {
            font-size: $font-size-50;
            color: #fff;
            font-weight: 600;
            padding-bottom: 20px;
        }
        p {
            font-size: $font-size-28;
            color: #fff;
            font-weight: normal;
            line-height: 1.7;
            text-align: justify;
        }
    }
    .border {
        border: 10px solid #fff;
        height: 218px;
        margin: auto 5%;
        background: #fff;
    }
    .right-side {
        .img-responsive {
            padding-bottom: 10px;
        }
        h4  {
            font-size: $font-size-28;
            color: #fff;
            font-weight: normal;
            text-align: center;
            text-transform: uppercase;
            line-height: 1.3;
        }
    }
}

#blocBurst {
    .carousel {
        position: relative;
        min-height: 400px;
        @media screen and (max-width: $size-xs-max){
            height: 650px;
            .bloc {
                width: 50%!important;
            }
        }
        #carousel-prev, #carousel-next {
            top: 50%;
            cursor: pointer;
            position: absolute;
            transform: translateY(-50%);
            z-index: 3;
            font-size: 35px;
            transition: .3s;
            i {
                color: $color-primary;
                transition: .3s;
            }
        }
        #carousel-prev {
            left: 10%;
            i:hover {
                transition: .3s;
                transform: translateX(-50%);
            }
        }
        #carousel-next {
            right: 10%;
            i:hover {
                transition: .3s;
                transform: translateX(50%);
            }
        }
        .bloc {
            max-width: 400px;
            width: 30%;
            opacity: 1 !important;

            .texte {
              text-align: center;
              padding-top: 15%;
                p {
                  font-weight: normal;
                  font-size: $font-size-24;
                  color: #000;
                  line-height: 1.3;
                  padding-bottom: 35px;
                }
                a.btnBurst {
                    background: #0889b0;
                    padding: 15px 40px;
                    color: #fff;
                    border-radius: 10px;
                    width: 62%;
                    display: block;
                    margin: auto;
                    text-transform: uppercase;
                    font-size: $font-size-20;
                    line-height: 1.3;
                    transition: 0.3s;
                    &:hover {
                        background: #000;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

section.historique {
    padding: 60px 10%;

    div.title {
        display: flex;
    }
    h3 {
        font-size: $font-size-60;
        color: $color-black;
        padding-left: 20px;
    }
    p {
      font-size: $font-size-22;
      line-height: 1.7;
      padding-bottom: 30px;
      text-align: justify;
    }
    h4 {
        font-size: $font-size-28;
        color: $color-black;
        padding: 20px 0;
        text-decoration: underline;
    }

    div.image-text {

        .image-side {
            width: 40%;
        }
        .text-side {
            width: 60%;
            padding-left: 1rem;
        }
        div.logos-box {
            flex-wrap: wrap;
            & > * {
                width: 20%;
                min-width: 150px;
            }
        }
        @media screen and (max-width: 600px){
            flex-direction: column-reverse;
            .image-side {
                width: 100%;
            }
            .text-side {
                width: 100%;
                padding-left: 0;
            }
        }
    }
}


section.section-blue-text {
    @extend .historique;
    background-color: $color-primary;
    h3, h4, p {
      color: $color-white;
    }

    @media screen and (max-width: $size-xs-max){
        .div-columns {
            flex-direction: column;
            & > div {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


section.galerie-fiche {
    @extend section.historique;

    div.link-container {
        padding: 40px 0;
        display: flex;
        a {
            color: $color-primary;
            font-size: $font-size-30;
            font-weight: bold;
            text-transform: uppercase;
            span {
              text-decoration: underline!important;
            }
        }
    }
    div.image-box {
      position: relative;
      &:hover {
          div.overlay {
            opacity: 1;
            visibility: visible;
            transition: .5s;
          }
      }
    }
    div.overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
        background: rgba(0, 147, 216, 0.6);
    }

    h4 {
        color: $color-black;
        text-transform: uppercase;
        padding-top: 20px;
    }

    @media screen and (max-width: $size-md-max){
        div.image-text {
            flex-direction: column;
            align-items: center;
            .bande {
              display: none;
            }
        }
        text-align: center;
    }
}


section.section01-coordonnees {
    background-color: #0889b0;
    padding: 50px 10%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    p {
        color: $color-white;
        text-transform: uppercase;
        font-size: $font-size-24;
        font-weight: bold;
    }
    div.left-side {
        width: 40%;
    }
    @media screen and (max-width: 700px){
        div.left-side {
            width: 100%;
        }
        div.bande {display: none; }
    }
}

section.section-02-coordonnees {

    div.container-fluid.row {
        padding: 50px 10%;
        margin: 0;
    }
    div.left-side{
        width: 40%;
    }
    div.right-side {
        width: 60%;
    }

    div.title {
        h3 {
            color: $color-black;
            padding-top: 15px;
            padding-left: 20px;
            padding-bottom: 25px;
            font-size: $font-size-50;
            border-left: 15px solid $color-1;
        }
    }
    div.horaire {
      padding-left: 40px;
    }
    label {
      color: $color-black;
    }
    p {
      font-size: $font-size-24;
      line-height: 1.5;
      display: flex;
      span{width: 150px;}
    }
    @media screen and (max-width: $size-sm-max){
      div.container-fluid.row {
          flex-direction: column;
      }
      div.left-side, div.right-side {
          width: 100%;
      }
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1750px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    padding-left: 1rem;
    padding-right: 1rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    font-size: 1.2rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li.phone a div.phonenumber {
    font-size: 2.3rem;
}
}

@media screen and (max-width: 1730px) {
    .slideshow-wrap .caption {
        top: 40%;
        left: 40%;
    }
}

@media screen and (max-width: 1600px) {
    #blocBurst {
        padding: 50px 0 25px 0;
    }
}

@media screen and (max-width: 1575px) {

    nav.module-menu .menu-navbar .wrapper.left .logo {
        max-width: 410px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 1550px) {

    .slideshow-wrap .caption {
        top: 40%;
        left: 43%;
    }
    #blocIntro {
        align-items: center;
    }
}

@media screen and (max-width: 1450px) {

    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li.phone a div.phonenumber {
        font-size: 2rem;
    }
    nav.module-menu .menu-navbar .wrapper.right .main-menu .tel {
        right: 1%;
    }
}

@media screen and (max-width: 1400px) {

    #blocBurst {
        padding: 75px 0 50px 0;
    }
    nav.module-menu .menu-navbar .wrapper.left .logo {
        max-width: 385px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 1335px) {

    footer {
        padding: 35px 5% 0 5%;
        clip-path: none;
        margin-top: 0;
    }
    .carousel {
        height: 55vw;
    }
    .slideshow-wrap .caption {
        top: 40%;
        left: 48%;
    }
    nav.module-menu .menu-navbar .wrapper.left .logo {
        max-width: 315px;
        margin-right: 20px;
    }
}

@media screen and (max-width: $size-md-max) {

    #blocBurst .carousel {
        max-height: 800px;
    }
}

@media screen and (max-width: 1250px) {

    #blocBurst .carousel .bloc {
        width: 32%;
    }
}

@media screen and (max-width: 1200px) {

    nav.module-menu .menu-navbar .wrapper.right {
        position: absolute;
        right: 0;
        top: 30%;
    }
}

@media screen and (max-width: 1115px) {

    #blocBurst .carousel .bloc {
        width: 35%;
    }
    .carousel {
        height: 60vw;
    }
    #blocIntro {
        flex-direction: column;
    }
    #blocIntro .left-side {
        width: 100%;
        padding-bottom: 25px;
    }
    #blocIntro .border {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    footer .footer-wrapper .blocMenu a {
        padding-right: 30px;
    }
    footer .footer-wrapper .blocCopyright .copyright-mediaweb .copyright {
        width: 40%;
    }
    #blocIntro {
        padding: 100px 5% 100px 5%;
    }

    .slideshow-wrap .texteBanniere {
        position: absolute;
        left: -55px;
        top: -20px;

        h3,h2 {
            font-size: 2.5rem;
            text-align: left;
        }
        .bande {
            padding-top: 8px;
            img {
                height: 175px;
            }
        }
    }
}

@media screen and (max-width: $size-sm-max) {

#blocBurst .carousel .bloc {
    width: 45%;
}
.carousel {
    height: 70vw;
}
.slideshow-wrap .caption .texteBanniere h3 {
    font-size: 2.6rem;
}
#blocIntro {
    padding: 70px 5%;
}
.slideshow-wrap .texteBanniere h3 {
    font-size: 2.1rem;
}
.slideshow-wrap .texteBanniere h2 {
    padding-bottom: 5px;
}
}

@media screen and (max-width: $size-xs-max) {

footer .footer-wrapper .blocMenu {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    a {
      padding-right: 0;
      padding-bottom: 20px;
    }
}

footer {
    min-height: 375px;
    padding: 45px 5%;
    clip-path: none;
    margin-top: 0;
}
footer .footer-wrapper .blocCopyright {
    padding-top: 30px;
    border-top: 1px solid #fff;
}
footer .footer-wrapper .blocCopyright {
    flex-direction: column-reverse;
}
footer .footer-wrapper .blocCopyright .copyright-mediaweb {
    padding-top: 25px;
    flex-direction: column;
}
footer .footer-wrapper .blocCopyright .copyright-mediaweb .copyright {
    width: 100%;
    padding-bottom: 15px;
}
.slideshow-wrap .texteBanniere {
    position: absolute;
    left: -25px;
    top: 50px;
}
#blocIntro {
    padding: 65px 5%;
}
#blocBurst .carousel .bloc {
    width: 70% !important;
    position: absolute;
    left: 1%;
    opacity: 0.15 !important;
}
#blocBurst .carousel .bloc.carousel-item.active {
    opacity: 1.5 !important;
}
#blocBurst .carousel .bloc .texte p {
    height: 75px;
    font-size: 1.1rem;
}
#blocBurst .carousel .bloc .texte a.btnBurst {
    width: 70%;
    font-size: 1rem;
}
#blocBurst {
    padding: 0;
}
.slideshow-wrap .caption .texteBanniere h2, .slideshow-wrap .caption .texteBanniere h3 {
    font-size: 2.5rem;
}
.slideshow-wrap .caption {
    top: 40%;
    left: 50%;
    width: 100%;
}
.slideshow-wrap .caption .texteBanniere .bande {
    padding-top: 5px;
}
#blocIntro h3 {
    font-size: 2.6rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 260px;
    margin-right: 0;
    padding-bottom: 20px;
}
}

@media screen and (max-width: 360px) {

#blocIntro h3 {
    font-size: 2.3rem;
}
.slideshow-wrap .texteBanniere h3 {
    font-size: 2rem;
}
.slideshow-wrap .texteBanniere h2 {
    font-size: 2.3rem;
}
}
