section.gallerie-products {
    padding: 50px 10%;

    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    div.grid-item {
        width: 33%;
        padding: 0 30px;
        max-width: 480px;
        position: relative;
        margin-bottom: 30px;
        transition: .5s;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3n + 4),
        &:nth-child(3n + 5){
          &:after{
              content: '';
              width: 10px;
              height: 50%;
              background-color: $color-primary;
              position: absolute;
              right: -5px;
              top: 10%;
          }
        }

        div.image-box {
          position: relative;
        }
        div.overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            transition: .5s;
            background: rgba(0, 147, 216, 0.6);
        }

        h4 {
            color: $color-black;
            text-transform: uppercase;
            padding-top: 20px;
        }
        &:hover {
            div.overlay {
              opacity: 1;
              visibility: visible;
              transition: .5s;
            }
        }
    }
    @media screen and (max-width: $size-md-max){
        div.grid-item {
            width: 50%;


            //&:nth-child(1),
            &:nth-child(2n){
            // &:nth-child(3n + 4),
            // &:nth-child(3n + 5){
              &:after{
                  display: none;
              }
            }

            &:nth-child(2n+1){
              &:after{
                  content: '';
                  width: 10px;
                  height: 50%;
                  background-color: $color-primary;
                  position: absolute;
                  right: -5px;
                  top: 10%;
              }
            }

        }
        @media screen and (max-width: $size-slider-breakpoint){
            div.grid-item {
                width: 100%;
                padding: 0;
                flex-direction: column;
                &:nth-child(2n+1){
                  &:after{
                      display: none;
                  }
                }
                div.image-side, div.text-side {
                    width: 100%;
                }
            }
        }
    }
}
