footer {
    background: #242422;
    min-height: 268px;
    padding: 100px 5% 35px 5%;
    clip-path: polygon(0 19%, 100% 0%, 100% 100%, 0% 100%);
    //padding: 195px 5% 35px 5%;
    //clip-path: polygon(0 29%, 100% 10%, 100% 100%, 0% 100%);
    //margin-top: -74px;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        flex-direction: column;
        .blocMenu {
            width: 100%;
            padding-bottom: 50px;
            a {
                font-weight: normal;
                font-size: $font-size-22;
                color: #fff;
                padding-right: 55px;
                &:last-child {
                    padding-right: 0;
                }
                &:hover {
                    color: #0889b0;
                }
            }
        }
        .blocCopyright {
            display: flex;
            width: 100%;
            padding-top: 50px;
            border-top: 1px solid #fff;
            .bloc-logo {
                display: flex;
                align-items: baseline;
                 .logo {
                    .img-responsive.mobile {
                        display: none;
                    }
                    &:hover {
                        .img-responsive.desktop {
                            display: none;
                        }
                        .img-responsive.mobile {
                            display: block;
                        }
                    }
                 }
            }
            .copyright-mediaweb {
                width: 100%;
                display: flex;

                .copyright {
                    //font-size: 15px;
                    width: 25%;
                    color: #fff;
                }
                .mediaweb {
                    //font-size: 15px;

                    a {
                        @extend .text-color-grey;
                        @extend .flex;
                        @extend .items-center;
                        img {
                            margin: -7px 0 0 0;
                        }
                        span { padding-left: 5px; color: #fff;}
                        &:hover {
                            @extend .text-color-contrast;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    width: $size-footer-width-mobile;
                }
            }
        }
    }
}
