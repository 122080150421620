.btn {
    background-color: $color-primary;
    color: $color-white!important;
    border: 3px solid lighten($color-primary, 20%);
    border-radius: $round-corner-radius;
    padding: 5px;
    font-size: $font-size-28;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        background-color: lighten($color-primary, 40%);
        border: 3px solid darken($color-primary, 40%);
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
